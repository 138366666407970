import React, { useState } from 'react';
import './SidebarRight.css';
import DropdownBelow from './DropdownBelow'; 
import SpotifyIconLight from "../Resources/spotifyLight.svg";
import SpotifyIconDark from "../Resources/spotifyDark.svg";
import BoltIconLight from "../Resources/boltLight.svg";
import BoltIconDark from "../Resources/boltDark.svg";

function SidebarRight({mode}) {
  
  return (
    <div className={`bottom-nav ${mode}`}>
      <DropdownBelow position="below" mode={mode} icon={
          <div className='top-wrapper'>
            <div className='spotify-logo'>
              <img src={mode === "light" ||mode === "tan"|| mode === "lightBlue" || mode === "lightRed"  ? SpotifyIconLight : SpotifyIconDark} className="icon-wrapper"/>
            </div>
            <div className='spacer'/>
            <div className='spotify-header'>Playlist</div>
          </div>
        }>
          <div className='spotify-wrapper'>
           <iframe src="https://open.spotify.com/embed/playlist/4roC096m54iHqsG3pUaLof?utm_source=generator" width="300px" height="375" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
           {/* <div className='afterdark'> button</div> */}

          </div>
      </DropdownBelow>
      
    </div>
  );
};

export default SidebarRight;
