import React from 'react';
import './Timer.css';
import LeftNav from './Toolbar';
import Sidebar from './Sidebar';
import DropdownBelow from './DropdownBelow';
import ResetIconLight from "../Resources/resetLight.svg";
import ResetIconDark from "../Resources/resetDark.svg";
import SettingsIconLight from "../Resources/settingsLight.svg";
import SettingsIconDark from "../Resources/settingsDark.svg";
let audio = document.getElementById("myAudio"); 
audio.volume = 0.8;

// function component
const AnimatedCard = ({ animation, digit, mode }) => {
  let currentMode = mode;
  return(
    <div className={`flipCard ${animation} ${currentMode}`}>
      <span>{digit}</span>
    </div>
  );
};

// function component
const StaticCard = ({ position, digit, mode }) => {
  let currentMode = mode;
  return(
  <div className={`${position} ${currentMode}`}>
  <span>{digit}</span>
  </div>
  );
};

// function component to display a digit with flip animation
const FlipUnitContainer = ({ digit, shuffle, unit, mode }) => {
  // assign digit values
  let currentDigit = digit;
  let previousDigit = digit + 1;
  let currentMode = mode;

  // to prevent a negative value
  if (unit !== 'hours') {
    previousDigit = previousDigit === -1 ? 59 : previousDigit;
  } else {
    previousDigit = previousDigit === -1 ? 23 : previousDigit;
  }

  // add zero
  if (currentDigit < 10) {
    currentDigit = `0${currentDigit}`;
  }
  if (previousDigit < 10) {
    previousDigit = `0${previousDigit}`;
  }

  // shuffle digits
  const digit1 = !shuffle ? previousDigit : currentDigit;
  const digit2 = shuffle ? previousDigit : currentDigit;

  // shuffle animations
  const animation1 = !shuffle ? 'fold' : 'unfold';
  const animation2 = shuffle ? 'fold' : 'unfold';
  
  console.log("SC: " + currentDigit + " | SP: " + previousDigit)
  console.log("C: " + currentDigit + " | P: " + previousDigit)

  return (
    <div className={`flipTimerUnitContainer ${currentMode}`}>
      <StaticCard position={'upperCard'} digit={currentDigit} mode={`${currentMode}`} />
      <StaticCard position={'lowerCard'} digit={previousDigit} />
      <AnimatedCard digit={digit1} animation={animation1} mode={`${currentMode}`} />
      <AnimatedCard digit={digit2} animation={animation2} mode={`${currentMode}`} />
    </div>
  );
};

// class component for the timer
class Timer extends React.Component {
  constructor(props) {
    super(props);
    let mode = localStorage.getItem('mode');
        if (!mode) {
        mode = 'mode1';
        }
    let hours = localStorage.getItem('hours');
      if (!hours) {
      hours = 0;
      }
    let minutes = localStorage.getItem('minutes');
      if (!minutes) {
      minutes = 10;
      }
    this.state = {
      hours: parseInt(hours),
      hoursShuffle: true,
      minutes: parseInt(minutes),
      minutesShuffle: true,
      seconds: 0,
      secondsShuffle: true,
      timerStarted: false,
      timerPaused: false,
    };
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.updateTimer(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  // function to update the timer
  updateTimer() {
    if (this.state.timerStarted && !this.state.timerPaused) {
      // decrement seconds
      let seconds = this.state.seconds - 1;
      // console.log("OG: " + this.state.seconds + " | " + seconds )
      let minutes = this.state.minutes;
      let hours = this.state.hours;

      if (hours === 0 && minutes === 0 && seconds === 0) {
        audio.play();
      }
      // if seconds reach 0, decrement minutes and reset seconds
      if (seconds === -1) {
        minutes -= 1;
        seconds = 59;
      }
      // if minutes reach 0, decrement hours and reset minutes
      if (minutes === -1) {
        hours -= 1;
        minutes = 59;
      }

      // if hours reach 0, reset timer
      if (hours === -1) {
        this.resetTimer();
        return;
      }

      // if minutes change, update minutes and shuffle state
      if (minutes !== this.state.minutes) {
        this.setState({
          minutes,
          minutesShuffle: !this.state.minutesShuffle,
        });
      }

      // if hours change, update hours and shuffle state
      if (hours !== this.state.hours) {
        this.setState({
          hours,
          hoursShuffle: !this.state.hoursShuffle,
        });
      }

      // on second chanage, update seconds and shuffle state
        if( seconds !== this.state.seconds) {
            this.setState({
                seconds,
                secondsShuffle:!this.state.secondsShuffle
            });
        }

      // update state
      this.setState({
        hours,
        minutes,
        seconds,
        secondsShuffle: !this.state.secondsShuffle,
      });

      
    }
  }

  // function to start the timer
  startTimer() {
    this.setState({
      timerStarted: true,
      timerPaused: false,
    });
  }

  handleInputChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    this.setState({
      [name]: value
    }, () => {
      localStorage.setItem('hours', this.state.hours);
      localStorage.setItem('minutes', this.state.minutes);
    });
  }

  
  // function to pause the timer
  pauseTimer() {
    this.setState({
    timerPaused: !this.state.timerPaused,
    });
    }
    
  // function to reset the timer
  resetTimer() {
    this.setState({
      hours: localStorage.getItem('hours'),
      minutes: localStorage.getItem('minutes'),
      seconds: 0,
      timerStarted: false,
      timerPaused: false,
    });
  }

  // function to set the timer
  setTimer(hours, minutes) {
    this.setState({
      hours,
      minutes,
      seconds: 0,
      timerStarted: false,
      timerPaused: false,
    });
  }

  render() {
    const {
      hours,
      hoursShuffle,
      minutes,
      minutesShuffle,
      seconds,
      secondsShuffle,
      timerStarted,
      timerPaused,
      timerType,
      pomodoro,
      shortBreak,
      longBreak,
      settingsOpen,
    } = this.state;
    return (
    <div>
      <div className={`time-wrapper ${this.props.mode}`}>
      <div className="timer-space"></div>
      {/* <LeftNav /> */}
        <div className={'flipClock'}>
          <FlipUnitContainer
            digit={this.state.hours}
            shuffle={this.state.hoursShuffle}
            unit="hours"
            mode={this.props.mode}
          />
          <div className='spacer'/>
          <FlipUnitContainer
            digit={this.state.minutes}
            shuffle={this.state.minutesShuffle}
            unit="minutes"
            mode={this.props.mode}
          />
          <div className='spacer'/>
          <FlipUnitContainer
            digit={this.state.seconds}
            shuffle={this.state.secondsShuffle}
            unit="seconds"
            mode={this.props.mode}
          />
        </div>
        <div className="type-wrapper">
          <div className="timerControls">
            <div className='timerButtons'>
                {timerStarted ? (
                  <div className= {`startPauseButton ${this.props.mode}`} onClick={() => this.pauseTimer()}>
                    {timerPaused ? 'Start' : 'Pause'}
                  </div>
                ) : (
                  <div className= {`startPauseButton ${this.props.mode}`} onClick={() => this.startTimer()}>Start</div>
                )}
              </div>
              <div className='spacer'></div>
            <div onClick={() => this.resetTimer()}>
              <img src={(this.props.mode) === "light" ||(this.props.mode) === "tan" ? ResetIconLight : ResetIconDark} className="icon-wrapper"/>
            </div>
          
          <div className='spacer'></div>
          <DropdownBelow mode={this.props.mode} icon={<div><img src={(this.props.mode) === "light" ||(this.props.mode) === "tan" ? SettingsIconLight : SettingsIconDark} className="icon-wrapper"/></div>} position="above" customHeight='125px' customWidth='250px'>
          <div className="modal-header">Settings</div>
          <div className="timer-wrapper">
            <div className='timerInput-wrapper'>
              Hours:
              <input
                maxlength="2"
                type="number"
                placeholder="Hours"
                value={this.state.hours}
                onChange={(event) => {
                  this.setTimer(event.target.value, this.state.minutes);
                  this.handleInputChange(event);
                  }}
              />
            </div>
            <div className='timerInput-wrapper'>
              Minutes:
              <input
                maxlength="2"
                type="number"
                placeholder="Minutes"
                value={this.state.minutes}
                onChange={(event) => {
                  this.setTimer(this.state.hours, event.target.value);
                  this.handleInputChange(event);
                  }}
              />
            </div>
          </div>
        </DropdownBelow>
        </div>
        </div >
        
      </div>
        {/* <Sidebar isOpen={isSidebarOpen} onClose={close} /> */}
      </div>
      
    );
  }
}

export default Timer;