import React from 'react';
import './Timer.css';
import './Pomodoro.css';
import LeftNav from './Toolbar';
import DropdownBelow from './DropdownBelow';
import SettingsIconLight from "../Resources/settingsLight.svg";
import SettingsIconDark from "../Resources/settingsDark.svg";
import ResetIconLight from "../Resources/resetLight.svg";
import ResetIconDark from "../Resources/resetDark.svg";

let audio = document.getElementById("myAudio"); 
audio.volume = 0.8;

// function component
const AnimatedCard = ({ animation, digit, mode }) => {
  let currentMode = mode;
  return(
    <div className={`flipCard ${animation} ${currentMode}`}>
      <span>{digit}</span>
    </div>
  );
};

// function component
const StaticCard = ({ position, digit, mode }) => {
  let currentMode = mode;
  return(
  <div className={`${position} ${currentMode}`}>
  <span>{digit}</span>
  </div>
  );
};


// function component to display a digit with flip animation
const FlipUnitContainer = ({ digit, shuffle, unit, mode }) => {
  // assign digit values
  let currentDigit = digit;
  let previousDigit = digit + 1;
  let currentMode = mode;

  // to prevent a negative value
  if (unit !== 'hours') {
    previousDigit = previousDigit === -1 ? 59 : previousDigit;
  } else {
    previousDigit = previousDigit === -1 ? 23 : previousDigit;
  }

  // add zero
  if (currentDigit < 10) {
    currentDigit = `0${currentDigit}`;
  }
  if (previousDigit < 10) {
    previousDigit = `0${previousDigit}`;
  }

  // shuffle digits
  const digit1 = shuffle ? previousDigit : currentDigit;
  const digit2 = !shuffle ? previousDigit : currentDigit;

  // shuffle animations
  const animation1 = shuffle ? 'fold' : 'unfold';
  const animation2 = !shuffle ? 'fold' : 'unfold';

  return (
    <div className={`flipUnitContainer ${currentMode}`}>
      <StaticCard position={'upperCard'} digit={currentDigit} mode={`${currentMode}`} />
      <StaticCard position={'lowerCard'} digit={previousDigit} mode={`${currentMode}`}/>
      <AnimatedCard digit={digit1} animation={animation1} mode={`${currentMode}`} />
      <AnimatedCard digit={digit2} animation={animation2} mode={`${currentMode}`} />
    </div>
  );
};

// class component for the timer
class Pomodoro extends React.Component {
  constructor(props) {
  super(props);
  let mode = localStorage.getItem('mode');
        if (!mode) {
        mode = 'mode1';
        }
  let pomodoro = localStorage.getItem('pomodoro');
    if (!pomodoro) {
        pomodoro = 25;
    }
  let shortBreak = localStorage.getItem('shortBreak');
    if (!shortBreak) {
      shortBreak = 5;
    }
  
  let longBreak = localStorage.getItem('longBreak');
    if (!longBreak) {
      longBreak = 15;
    }
  let timerType = 'pomodoro'
  this.state = {
  pomodoro: pomodoro,
  pomoCounter: 0,
  counter: 0,
  shortBreak: shortBreak,
  longBreak: longBreak,
  timerType: timerType,
  hours: 0,
  hoursShuffle: true,
  minutes: parseInt(pomodoro),
  minutesShuffle: true,
  seconds: 0,
  secondsShuffle: true,
  timerStarted: false,
  timerPaused: false,
  };
  }

componentDidMount() {
  this.timerID = setInterval(() => this.updateTimer(), 1000);
  
}

componentWillUnmount() {
clearInterval(this.timerID);
}

// function to update the timer
updateTimer() {
if (this.state.timerStarted && !this.state.timerPaused) {
// decrement seconds
let seconds = this.state.seconds - 1;
let minutes = this.state.minutes;
let hours = this.state.hours;

if (hours === 0 && minutes === 0 && seconds === 0) {
  audio.play();
}


// if seconds reach 0, decrement minutes and reset seconds
if (seconds === -1) {
    minutes -= 1;
    seconds = 59;
  }

  // if minutes reach 0, decrement hours and reset minutes
  if (minutes === -1) {
    hours -= 1;
    minutes = 59;
  }

  // if hours reach 0, reset timer and switch to next stage
  if (hours === -1) {
    this.switchTimerType();
    return;
  }

  // update state
  this.setState({
    hours: hours,
    minutes: minutes,
    seconds: seconds,
    secondsShuffle: !this.state.secondsShuffle,
  });
}
}

// function to switch between timer types
switchTimerType() {
const { timerType } = this.state;
  if (timerType === 'pomodoro') {
    if(this.state.counter < 3){
      this.setState({
        timerType: 'shortBreak',
        hours: 0,
        minutes: this.state.shortBreak,
        timerPaused: false,
        counter: this.state.counter+1,
        pomoCounter: this.state.pomoCounter+1
      });
    }else{
      this.setState({
        timerType: 'longBreak',
        hours: 0,
        minutes: this.state.longBreak,
        timerPaused: false,
        pomoCounter: this.state.pomoCounter+1
      });
    }
  } else if (timerType === 'shortBreak') {
    this.setState({
      timerType: 'pomodoro',
      hours: 0,
      minutes: this.state.pomodoro,
      timerPaused: false
    });
  } else if (timerType === 'longBreak') {
    this.setState({
      timerType: 'pomodoro',
      hours: 0,
      minutes: this.state.pomodoro,
      timerPaused: false,
      counter: 0,
    });
  }
}

// function to reset the timer
resetTimer() {
this.setState({
  timerType: 'pomodoro',
  hours: 0,
  minutes: this.state.pomodoro,
  seconds: 0,
  timerPaused: true,
  pomoCounter: 0 
});
}

// function to start the timer
startTimer() {
  this.setState({
  timerStarted: true,
  timerPaused: false,
  });
}

speedTimer() {
  this.setState({
  seconds: 1,
  });
}

// function to pause the timer
pauseTimer() {
  this.setState({
    timerPaused: !this.state.timerPaused,
  });
}

  // function to set the timer
  setTimer(pomodoro, minutes) {
    this.setState({
      pomodoro: pomodoro,
      minutes: minutes,
      seconds: 0,
      timerStarted: false,
      timerPaused: false,
    });
  }
    
    // function to handle change in pomodoro input
    handlePomodoroChange(e) {
      let value = e.target.value;
      let name = e.target.name;
      this.setState({
        [name]: value
      }, () => {
        localStorage.setItem('pomodoro', this.state.pomodoro)
      });
    }
    
    // function to handle change in short break input
    handleShortBreakChange(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      shortBreak: parseInt(value)
    }, () => {
      localStorage.setItem('shortBreak', e.target.value)
    });
    }
    
    // function to handle change in long break input
    handleLongBreakChange(e) {
    this.setState({
      longBreak:  localStorage.setItem('longBreak', e.target.value),
    });
    }

    // handleInputChange = (event) => {
    //   let value = event.target.value;
    //   let name = event.target.name;
    //   this.setState({
    //     [name]: value
    //   }, () => {
    //     localStorage.setItem('hours', this.state.hours);
    //     localStorage.setItem('minutes', this.state.minutes);
    //   });
    // }
    
    render() {
      const {
        hours,
        hoursShuffle,
        minutes,
        minutesShuffle,
        seconds,
        secondsShuffle,
        timerStarted,
        timerPaused,
        timerType,
        pomodoro,
        counter,
        pomoCounter,
        shortBreak,
        longBreak,
        settingsOpen,
      } = this.state;
    
      return (
        <div>
        <div className={`time-wrapper ${this.props.mode}`}>
          {/* <LeftNav /> */}
          <div className="type-wrapper">
            <div className={this.state.timerType === 'pomodoro' ? `currentTimer ${this.props.mode}` : ""}>
              Pomodoro
            </div>
            <div className='spacer2'></div>
            <div className={this.state.timerType === 'shortBreak' ? `currentTimer ${this.props.mode}` : ""}>
              Short Break
            </div>
            <div className='spacer2'></div>
            <div className={this.state.timerType === 'longBreak' ? `currentTimer ${this.props.mode}` : ""}>
              Long Break
            </div>
            <div className='spacer2'></div>
          </div>
          <div className={'flipClock'}>

              <FlipUnitContainer 
                digit={minutes} 
                shuffle={minutesShuffle} 
                unit='minutes' 
                mode={this.props.mode}
              />
            <div className='spacer'/>
              <FlipUnitContainer 
                digit={seconds} 
                shuffle={secondsShuffle} 
                unit='seconds' 
                mode={this.props.mode}
              />
          </div>
          <div className="control-wrapper">
            {/* {"# "}{this.state.pomoCounter} */}
            <div className='timerControls'>
            {/* <button onClick={() => this.speedTimer()}>
              fastmode
            </button> */}
            <div className='timerButtons'>
              {timerStarted ? (
                <div className= {`startPauseButton ${this.props.mode}`} onClick={() => this.pauseTimer()}>
                  {timerPaused ? 'Start' : 'Pause'}
                </div>
              ) : (
                <div className={`startPauseButton ${this.props.mode}`} onClick={() => this.startTimer()}>Start</div>
              )}
              <div className='spacer'></div>
              <div onClick={() => this.resetTimer()}>
                <img src={(this.props.mode) === "light" ||(this.props.mode) === "tan" || (this.props.mode) === "lightBlue" || (this.props.mode) === "lightRed"? ResetIconLight : ResetIconDark} className="icon-wrapper"/>
              </div>
              <div className='spacer'></div>
              <DropdownBelow icon={<div><img src={(this.props.mode) === "light" ||(this.props.mode) === "tan" || (this.props.mode) === "lightBlue" || (this.props.mode) === "lightRed"? SettingsIconLight : SettingsIconDark} className="icon-wrapper"/></div>} position="above" customLeft="-80px" mode={this.props.mode} customHeight='125px' customWidth='350px'>
              {/* <span className='timerLabel' onClick={() => this.closeSettings()}>
                  X
                </span> */}
                <div className="modal-header">Settings</div>
                <div className='timer-wrapper'>
                  <div className='timerInput-wrapper'>
                    <label htmlFor='pomodoro'>Pomodoro</label>
                    <input
                      maxLength="2"
                      type='number'
                      value={this.state.pomodoro}
                      onChange={(e) => {
                        this.setTimer(e.target.value, e.target.value);
                        this.handlePomodoroChange(e);
                        }}
                    />
                  </div>
                  <div className='timerInput-wrapper'>
                    <label htmlFor='shortBreak'>Short Break</label>
                    <input
                      maxLength="2"
                      type='number'
                      value={this.state.shortBreak}
                      onChange={(e) => this.handleShortBreakChange(e)}
                    />
                  </div>
                  <div className='timerInput-wrapper'>
                    <label htmlFor='longBreak'>Long Break</label>
                    <input
                      maxLength="2"
                      type='number'
                      value={this.state.longBreak}
                      onChange={(e) => this.handleLongBreakChange(e)}
                    />
                  </div>
                  {/* <button onClick={() => this.resetTimer()}>Reset</button> */}

                </div>
            </DropdownBelow>
            </div>
          </div>
          </div>
          
        </div>
        </div>
      );
    }
  }
export default Pomodoro;