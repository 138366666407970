// import logo from './logo.svg';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Home from './Components/Home';
import Timer from './Components/Timer';
import Pomodoro from './Components/Pomodoro';
import NotFound from './Components/NotFound';
import Toolbar from './Components/Toolbar';
import DropdownBelow from './Components/DropdownBelow';
import Checkin from './Components/Checkin';
import About from './Components/About';
import ClockFree from './Components/ClockFree';
import ClockSignedIn from './Components/ClockSignedIn';

import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ReactModal from 'react-modal';
import {EmailIcon,FacebookIcon,LinkedinIcon,PinterestIcon,RedditIcon,TelegramIcon, TwitterIcon, WhatsappIcon} from "react-share";
import {EmailShareButton, FacebookShareButton, LinkedinShareButton, PinterestShareButton, RedditShareButton, TelegramShareButton, TwitterShareButton,WhatsappShareButton} from "react-share";
import fullscreenLight from "./Resources/fullscreenLight.svg";
import fullscreenDark from "./Resources/fullscreenDark.svg";
import fullscreenCloseLight from "./Resources/fullscreenCloseLight.svg";
import fullscreenCloseDark from "./Resources/fullscreenCloseDark.svg";
import colorLight from "./Resources/colorLight.svg";
import colorDark from "./Resources/colorDark.svg";
import shareLight from "./Resources/shareLight.svg";
import shareDark from "./Resources/shareDark.svg";
import Plus from './Plus';
import Free from './Free';
import Signedin from './Signedin';

import SidebarRight from './Components/SidebarRight';
import './App.css';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import PomodoroSignedin from './Components/PomodoroSignedin';

let audio = document.getElementById("myAudio"); 
audio.volume = 0.8;

function App() {

  const modeClassNames = {
    dark: 'dark',
    neon: 'neon',
    darkBlue: 'darkBlue',
    darkRed: 'darkRed',
    light: 'light',
    tan: 'tan',
    lightBlue: 'lightBlue',
    lightRed: 'lightRed',
    afterDark: 'afterDark'
  };

  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setIsHidden(true);
    }, 5000);

    document.addEventListener('mousemove', () => {
      clearTimeout(timeoutId);
      setIsHidden(false);

      timeoutId = setTimeout(() => {
        setIsHidden(true);
      }, 5000);
    });

    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener('mousemove', () => {});
    };
  }, []);

  console.log(localStorage.getItem('mode'))
  const [currentMode, setCurrentMode] = React.useState(localStorage.getItem('mode') || "dark");
  const handle = useFullScreenHandle();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const shareUrl = "https://thinker.so";
  const title = 'Thinker';

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleModeChange = (mode) => {
    localStorage.setItem('mode', mode);
    setCurrentMode(mode);
  }

  const selectedMode = modeClassNames[currentMode];
  const [fullScreen, setFullScreen] = React.useState(false);
  const toggleFullScreen = () => setFullScreen(!fullScreen);

  return (
    <Router>
      <FullScreen handle={handle}>   
      <div className={`topnav-wrapper ${selectedMode}`}>
        <div className='logo-wrapper'>Pomoflip</div>
        <div className='spacer-nav'/>
        <div className="navi-wrapper">
          <DropdownBelow  mode={selectedMode} icon={<div><Checkin/></div>} position="below">
            <div className="level-wrapper">
              <div className='level-header'>Your level:</div>
              <div className="spacer-nav"/>
              <div className="level">
                <Checkin/>
              </div>
              <div className="level-description">
              <i>Come back tomorrow to level up!</i>
            </div>
            </div>
          </DropdownBelow>
        </div>
         <div className='spacer'/>
        <DropdownBelow mode={selectedMode} customLeft="80px" icon={<div><img src={selectedMode === "light" || selectedMode === "tan" || selectedMode === "lightBlue" || selectedMode === "lightRed"? colorLight : colorDark} className="icon-wrapper"/></div>} position="below">
          <div className="mode-buttons">
            <div className="mode-wrapper">
              <div className="dark-select" onClick={() => handleModeChange('dark')}> </div>
              <div className="spacer"/>
              <div className="neon-select" onClick={() => handleModeChange('neon')}> </div>
              <div className="spacer"/>
              <div className="darkBlue-select" onClick={() => handleModeChange('darkBlue')}> </div>
              <div className="spacer"/>
              <div className="darkRed-select" onClick={() => handleModeChange('darkRed')}> </div>
            </div>
            <div className="mode-wrapper">
              <div className="light-select" onClick={() => handleModeChange('light')}> </div>
              <div className="spacer"/>
              <div className="tan-select" onClick={() => handleModeChange('tan')}> </div>
              <div className="spacer"/>
              <div className="lightBlue-select" onClick={() => handleModeChange('lightBlue')}> </div>
              <div className="spacer"/>
              <div className="lightRed-select" onClick={() => handleModeChange('lightRed')}> </div>

            </div>
          </div>
        </DropdownBelow>
        <div className='spacer-nav'/>
        <div className="navi-wrapper">
          <DropdownBelow className="navi-wrapper" mode={selectedMode} icon={<div><img src={selectedMode === "light" ||selectedMode === "tan" || selectedMode === "lightBlue" || selectedMode === "lightRed" ? shareLight : shareDark} className="icon-wrapper-small"/></div>} position="below" customHeight='125px' customWidth='280px'>
            <div className='share-wrapper'>
              <div className='level-header'>
                Share
              </div>
              <br/>
              <div>
                <EmailShareButton
                  url={shareUrl}
                  quote={title}>
                  <EmailIcon size={32} round={true} />
                </EmailShareButton>
                <FacebookShareButton
                  url={shareUrl}
                  quote={title}
                >
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <TwitterShareButton
                  url={shareUrl}
                  quote={title}>
                  <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <PinterestShareButton
                  url={shareUrl}
                  quote={title}>
                  <PinterestIcon size={32} round={true}/>
                </PinterestShareButton>

                <LinkedinShareButton
                  url={shareUrl}
                  quote={title}>
                <LinkedinIcon size={32} round={true} />
                </LinkedinShareButton>
                <RedditShareButton
                  url={shareUrl}
                  quote={title}>
                  <RedditIcon size={32} round={true} />
                </RedditShareButton>
                <TelegramShareButton
                  url={shareUrl}
                  quote={title}>
                  <TelegramIcon size={32} round={true} />
                </TelegramShareButton>
                <WhatsappShareButton
                  url={shareUrl}
                  quote={title}>
                  <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
                
              </div>
            </div>
            
          </DropdownBelow>
        </div>
        <div className='spacer'/>
        <div className="top-wrapper">
          {fullScreen ? (
          <div onClick={() => { handle.exit(); toggleFullScreen()}}>
            <img src={selectedMode === "light" ||selectedMode === "tan" || selectedMode === "lightBlue" || selectedMode === "lightRed"? fullscreenCloseLight : fullscreenCloseDark} className="icon-wrapper-small"/>
          </div>
          ) : (
          <div onClick={() => { handle.enter(); toggleFullScreen()}}>
            <img src={selectedMode === "light" ||selectedMode === "tan" || selectedMode === "lightBlue" || selectedMode === "lightRed"? fullscreenLight : fullscreenDark} className="icon-wrapper-small"/>
          </div>
          )}
        </div>
        
      </div>
      {/* Hides the toolbar */}
      <div className={`${isHidden ? 'hidden' : ''}`}>
        <SidebarRight mode={selectedMode}/>
        <Toolbar mode={selectedMode}/>
      </div>
       {/* <SidebarRight mode={selectedMode}/>
        <Toolbar mode={selectedMode}/> */}

      <Routes>
        <Route path="/" element={<Pomodoro mode={selectedMode}/>} />
        <Route path="/timer" element={<Timer mode={selectedMode}/>} />
        <Route path="/clock" element={<Home mode={selectedMode}/>} />
        <Route path="/clockFree" element={<ClockFree mode={selectedMode} handleModeChange={handleModeChange}/>} />
        <Route path="/clockSignedIn" element={<ClockSignedIn mode={selectedMode} handleModeChange={handleModeChange}/>} />
        <Route path="/pomodoroSignedin" element={<PomodoroSignedin mode={selectedMode} handleModeChange={handleModeChange}/>} />
        <Route path="/free" element={<Free mode={selectedMode} handleModeChange={handleModeChange}/>} />
        <Route path="/signedin" element={<Signedin mode={selectedMode} handleModeChange={handleModeChange}/>} />
        <Route path="/plus" element={<Plus mode={selectedMode} handleModeChange={handleModeChange}/>} />
        <Route path="/about" element={<About mode={selectedMode}/>}/>
        <Route path="*" element={<NotFound/>} />
      </Routes>
      </FullScreen>
    </Router>
  );
}

export default App;
