import React, { useState, useRef, useEffect } from 'react';

function DropdownBelow({ icon, children, mode, position, customLeft, customHeight="100px", customWidth="200px" }) {
  const [showDiv, setShowDiv] = useState(false);
  const ref = useRef(null);
  console.log("CustomLeft: " +customLeft)
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowDiv(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  return (
    <div ref={ref} onClick={() => setShowDiv(!showDiv)} style={{ cursor: 'pointer'}}>
      {icon}
      <div
        onClick={e => e.stopPropagation()}
        className ="selectMode"
        style={{
          height: customHeight,
          width: customWidth,
          marginLeft: customLeft,
          backgroundColor: mode === 'light'||mode === 'tan'||mode === 'lightBlue'||mode === 'lightRed' ? '#F7F7F5' : '#1f1f1f',
          boxShadow: '0px 4px 4px rgba(206, 206, 206, 0.25)',
          display: showDiv ? 'block' : 'none',
          position: 'absolute',
          marginTop: position === 'above' ? '-160px' : '5px',
          transform: 'translateX(-40%)',
          borderRadius: '5px',
          zIndex: '10000'
        }}
      >
        {children}
      </div>
    </div>
  );  
}

export default DropdownBelow;
