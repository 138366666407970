import React from 'react';
import './About.css';

class About extends React.Component {

    constructor(props) {
        super(props);
        let mode = localStorage.getItem('mode');
        if (!mode) {
        mode = 'mode1';
        }
    }
  render() {
    return (
    <div className={`${this.props.mode}`}>
      <div className="centered-text">
        <div>
        We believe that the world is facing increasingly complex challenges, and we need a new breed of thinkers who can come up with innovative solutions that will make a difference.
        </div>

        <br/>

        <div>
        At Thinker, we are passionate about productivity and personal growth. We know that in order to achieve great things, you need to be able to manage your time effectively, focus on your goals, and stay motivated even when things get tough. That's why we offer a range of resources and support to help our members develop the skills and mindset they need to succeed.
        </div>

        <br/>

        <div>
        Our community is made up of thinkers from all walks of life, including entrepreneurs, scientists, artists, students, and educators. What unites us is a shared belief in the power of ideas and the potential of human creativity. We believe that anyone can be a thinker, regardless of their background or education, as long as they are willing to put in the time and effort to develop their skills.
        </div>

        <br/>
        
        <div>
        Whether you're just starting out on your journey or you're a seasoned professional looking for new challenges, we have the resources and support you need to achieve your goals and make an impact in the world. Join us today to begin your journey with productivity and personal growth.
        </div>
      </div>
      </div>
    );
  }
}

export default About;