import React from 'react';
import { Link } from 'react-router-dom';
import './Toolbar.css';
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import './SidebarRight.css';
import DropdownBelow from './DropdownBelow'; 
import SpotifyIconLight from "../Resources/spotifyLight.svg";
import SpotifyIconDark from "../Resources/spotifyDark.svg";
import BoltIconLight from "../Resources/boltLight.svg";
import BoltIconDark from "../Resources/boltDark.svg";

function Toolbar({mode}) {

    const currentMode = localStorage.getItem('mode') || "dark";

  return (
    <div className={`toolbar-wrapper`}>
        <div className={`toolbar ${currentMode}`}>
        
        <Link to="/plus">
            <div className='icon'>
                🍅
            </div>
            <div>
                pomodoro
            </div>
        </Link>
        <Link to="/clock">
            <div className='icon'>
                ⏰
            </div>
            <div>
                clock
            </div>
        </Link>
        <Link to="/timer">
            <div className='icon'>
                ⏳
            </div>
            <div>
                timer
            </div>
        </Link>
        {/* <a href="https://www.thinker.so/sign-up" target="_self" id="my-element" data-tooltip-content="In progress">
            <div className='progress'>
                <div className='icon'>
                    ⏰
                </div>
                <div >
                    alarm
                </div>
                
            </div>
            <Tooltip anchorId="my-element" place="top" />
        </a> */}
        <a href="https://pomoflip.canny.io/feature-request" target="_blank">
            <div className='icon'>
                <img src={mode === "light" ||mode === "tan" || mode === "lightBlue" || mode === "lightRed" ? BoltIconLight : BoltIconDark} className="icon-wrapper"/>
            </div>
        </a>
        
    </div>
    </div>
    
  );
}

export default Toolbar;
