import React from 'react';
import './Home.css';
import SidebarRight from './SidebarRight';


// function component
const AnimatedCard = ({ animation, digit, mode }) => {
    let currentMode = mode;
    return(
      <div className={`flipCard ${animation} ${currentMode}`}>
        <span>{digit}</span>
      </div>
    );
  };
  
// function component
const StaticCard = ({ position, digit, mode }) => {
    let currentMode = mode;
    return(
    <div className={`${position} ${currentMode}`}>
    <span>{digit}</span>
    </div>
    );
};

// function component
const FlipUnitContainer = ({ digit, shuffle, unit, mode }) => {	

    // assign digit values
    let currentDigit = digit;
    let previousDigit = digit - 1;
    let currentMode = mode;

    // to prevent a negative value
    if ( unit !== 'hours') {
        previousDigit = previousDigit === -1 
        ? 59 
        : previousDigit;
    } else {
        previousDigit = previousDigit === -1 
        ? 23 
        : previousDigit;
    }

    // add zero
    if ( currentDigit < 10 ) {
        currentDigit = `0${currentDigit}`;
    } 
    if ( previousDigit < 10 ) {
        previousDigit = `0${previousDigit}`;
    }

    // shuffle digits
    const digit1 = shuffle 
        ? previousDigit 
        : currentDigit;
    const digit2 = !shuffle 
        ? previousDigit 
        : currentDigit;
    const animation1 = shuffle 
        ? 'fold' 
        : 'unfold';
    const animation2 = !shuffle 
        ? 'fold' 
        : 'unfold';

    return(
        <div className={`flipUnitContainer ${currentMode}`}>
        <StaticCard 
            position={`upperCard`} 
            digit={currentDigit} 
            mode={`${currentMode}`}
            />
        <StaticCard 
            position={`lowerCard`} 
            digit={previousDigit} 
            mode= {`${currentMode}`}
            />
        <AnimatedCard 
            digit={digit1}
            animation={animation1}
            mode={`${currentMode}`}
            />
        <AnimatedCard 
            digit={digit2}
            animation={animation2}
            mode={`${currentMode}`}
            />
        </div>
    );
};

// class component
class Home extends React.Component {
    
    constructor(props) {
        super(props);
        let mode = localStorage.getItem('mode');
        if (!mode) {
        mode = 'mode1';
        }
        let time = new Date();
        this.state = {
            hours: (time.getHours()  + 24) % 12 || 12,
            hoursShuffle: true,
            minutes: time.getMinutes(),
            minutesShuffle: true,
            seconds: time.getSeconds(),
            secondsShuffle: true,
            ampm: (time.getHours() >= 12) ? "PM" : "AM",
        };
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.updateTime(),
            50
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    updateTime() {
        // get new date
        const time = new Date();
        // set time units
        const hours = (time.getHours()  + 24) % 12 || 12;
        const minutes = time.getMinutes();
        const seconds = time.getSeconds();
        //am or pm
        if (time.getHours() >= 12) {
            this.setState({ ampm: "PM" });
          }
          else{
            this.setState({ ampm: "AM" });
          }
        // on hour chanage, update hours and shuffle state
        if( hours !== this.state.hours) {
            const hoursShuffle = !this.state.hoursShuffle;
            this.setState({
                hours,
                hoursShuffle
            });
        }
        // on minute chanage, update minutes and shuffle state
        if( minutes !== this.state.minutes) {
            const minutesShuffle = !this.state.minutesShuffle;
            this.setState({
                minutes,
                minutesShuffle
            });
        }
        // on second chanage, update seconds and shuffle state
        if( seconds !== this.state.seconds) {
            const secondsShuffle = !this.state.secondsShuffle;
            this.setState({
                seconds,
                secondsShuffle
            });
        }
    }

    render() {
    
    // state object destructuring
    const { 
    hours, 
    minutes, 
    seconds, 
    ampm,
    hoursShuffle, 
    minutesShuffle, 
    secondsShuffle
    } = this.state;
        
    return(
    <div>
        <div className={`time-wrapper ${this.props.mode}`}>
        <div className={`ampm-wrapper ${this.props.mode}`}>{ampm}</div>
        <div className={'flipClock'}>
            <FlipUnitContainer 
                unit={'hours'}
                digit={hours} 
                shuffle={hoursShuffle} 
                mode={this.props.mode}
            />
                
            <div className='spacer'/>
            <FlipUnitContainer 
                unit={'minutes'}
                digit={minutes} 
                shuffle={minutesShuffle} 
                mode={this.props.mode}
                />
            {/* <FlipUnitContainer 
                unit={'seconds'}
                digit={seconds} 
                shuffle={secondsShuffle} 
                /> */}
        </div>
        </div>
    </div>
    );
    }
}

export default Home;
