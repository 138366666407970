import React, { useState, useEffect } from "react";

const Checkin = () => {
  
  const [count, setCount] = useState(0);
  console.log("count: " + count)
  const [today, setToday] = useState(new Date().toDateString());

  useEffect(() => {
    const storedCount = localStorage.getItem("count");
    const storedToday = localStorage.getItem("today");

    if (!storedCount){
      localStorage.setItem("count", 0);
    }
    else if (storedCount && storedToday === today) {
      setCount(parseInt(storedCount));
    } else if (today !== storedToday) {
      localStorage.setItem("count", parseInt(storedCount) + 1);
      localStorage.setItem("today", today);
    }
  }, [today]);

  return (
    <div>
      {count}
    </div>
  );
};

export default Checkin;
