import { Link } from 'react-router-dom';
import './Toolbar';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import BoltIconLight from "../Resources/boltLight.svg";
import BoltIconDark from "../Resources/boltDark.svg";

function ToolbarFree({mode}) {
  return (
    <div className={`toolbar-wrapper`}>
        <div className={`toolbar ${mode}`}>
        <Link to="/free">
            <div className='icon'>
                🍅
            </div>
            <div>
                pomodoro
            </div>
        </Link>
        <Link to="/clockFree">
            <div className='icon'>
            ⏰
            </div>
            <div>
                clock
            </div>
        </Link>
        <a href="https://www.thinker.so/sign-up" target="_parent" >
            <div className='icon'>
                ⏳
            </div>
            <div>
                timer
            </div>
        </a>
        {/* <a href="https://www.thinker.so/sign-up" target="_parent" id="my-element" data-tooltip-content="In progress">
            <div className='progress'>
                <div className='icon'>
                    ⏰
                </div>
                <div >
                    alarm
                </div>
                
            </div>
            <Tooltip anchorId="my-element" place="top" />
        </a> */}
        <a href="https://pomoflip.canny.io/feature-request" target="_blank">
            <div className='icon'>
                <img src={mode === "light" ||mode === "tan" || mode === "lightBlue" || mode === "lightRed" ? BoltIconLight : BoltIconDark} className="icon-wrapper"/>
            </div>
        </a>
    </div>
    </div>
    
  );
}

export default ToolbarFree;
